import React from "react";
import Form from "../components/Form/Form_v2";

export default function Contacto({ children }) {
  const formConfig = {
    submit: { value: "Enviar Mensaje", className: "globalButton1" },
    meta: {
      formId: "mensaje-contacto-form",
      onSubmit: ({ formData }) => {
        console.info("mensaje-contacto-form: ", JSON.stringify(formData));
      },
    },
    inputs: [
      {
        hint: "Nombre",
        type: "text",
        name: "nombre",
        required: true,
      },
      {
        hint: "Email",
        type: "email",
        name: "email",
        required: true,
      },
      {
        hint: "Mensaje",
        type: "textarea",
        name: "mensaje",
        required: true,
      },
    ],
  };

  return (
    <section id="Contacto">
      {children}
      <div className="sectionHeaders">
        <h1>Contacto</h1>
        <h2 className="fondo">Todos los caminos llegan a Danny's</h2>
      </div>
      <div className="sectionMain">
        <div className="mapa">
          <div className="header">
            <p>Ven a conocer nuestro local al paso, ubicado en:</p>
            <h3>Diagonal Rancagua #946, Providencia, Region Metropolitana</h3>
          </div>
          <div className="content">
            <iframe
              title="Dirección Danny's New York style Pizza"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6658.74426849105!2d-70.620659!3d-33.43961!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfb3c8e8187cfd8bd!2sDanny%C2%B4s%20New%20York%20Style%20Pizza!5e0!3m2!1ses!2scl!4v1662151837828!5m2!1ses!2scl"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
        <div className="mensajeContacto">
          <div className="header">
            <p>Contactanos! Dejanos tu información para cotizar tus eventos</p>
          </div>
          <div className="content">
            <Form {...formConfig} />
          </div>
        </div>
      </div>
    </section>
  );
}
