import { useState, useEffect, useReducer } from "react";
import { eventBus } from "../events/EventHandler";

export function useWindowDimensions() {
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export function useBinaryState(boolean) {
  const [isActive, setIsActive] = useState(false);
  const changeActive = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    if (boolean) setIsActive(true);
  }, [boolean]);

  return { isActive, changeActive };
}

export function useShoppingCartController_v2() {
  const reducer = (state, action) => {
    const itemObj = state[action.id];
    switch (action.type) {
      case "add":
        if (itemObj) {
          return { ...state, [action.id]: state[action.id] + 1 };
        } else {
          return { ...state, [action.id]: 1 };
        }
      case "remove":
        if (itemObj) {
          if (itemObj > 1) {
            return { ...state, [action.id]: state[action.id] - 1 };
          } else {
            delete state[action.id];
            return { ...state };
          }
        }
        break;
      case "init":
        return { ...action.data };
      default:
    }
  };

  const [shoppingCart, setShoppingCart] = useReducer(reducer, {});

  // Hook que permite agregar o quitar items del carrito de compra, segun su id
  function useOrderButtons(id) {
    const shoppingCartProductCounter = shoppingCart[id] ? shoppingCart[id] : 0;
  
    const addProduct = () => {
      setShoppingCart({ type: "add", id: id });
    };
  
    const removeProduct = () => {
      setShoppingCart({ type: "remove", id: id });
    };
  
    return {
      prodCounter: shoppingCartProductCounter,
      addProduct,
      removeProduct,
    };
  }

  let shoppingCartItemCounter = 0;
  for (const keyItem in shoppingCart) {
    if (Object.hasOwnProperty.call(shoppingCart, keyItem)) {
      const item = shoppingCart[keyItem];
      shoppingCartItemCounter += item;
    }
  }

  // Obtener carrito de compra si existe en el almacenamiento local del browser
  useEffect(() => {
    const carritoAlmacenamiento =
      window.localStorage.getItem("shoppingCartData");
    if (carritoAlmacenamiento && typeof carritoAlmacenamiento === "string") {
      const carritoObj = JSON.parse(carritoAlmacenamiento);
      setShoppingCart({ type: "init", data: carritoObj });
    }
  }, []);

  // Publicación de actualización de items del carrito de compra
  useEffect(() => {
    if (shoppingCart)
      eventBus.publish({
        view: "MainView",
        component: "CarritoCompra",
        event: "carrito-compra-actualizado",
        payload: shoppingCart,
      });
  }, [shoppingCart]);

  // Actualización de carrito de compra en el almacenamiento local del browser
  useEffect(() => {
    if (shoppingCart) {
      const strState = JSON.stringify(shoppingCart);
      window.localStorage.setItem("shoppingCartData", strState);
    }
  }, [shoppingCart]);

  return { shoppingCart, shoppingCartItemCounter, useOrderButtons };
}
