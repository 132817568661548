import React from "react";

export default function Promociones({ children }) {
  const images = [
    { img: "/images/promociones/LUNESHISTORIA.jpg", alt: "Lunes" },
    { img: "/images/promociones/marteshistoria.jpg", alt: "Martes" },
    { img: "/images/promociones/miercoleshistoria.jpg", alt: "Miércoles" },
    { img: "/images/promociones/historiajueves.jpg", alt: "Jueves" },
    { img: "/images/promociones/vierneshistorias.jpg", alt: "Viernes" },
    { img: "/images/promociones/sabadohistorias.jpg", alt: "Sábado" },
  ];

  return (
    <section id="promociones">
      {children}
      <h1>promociones</h1>
      <div className="sectionMain">
        <h2 className="fondo">
          15% de descuento en tu pizza del dia, retiro en local o delivery
        </h2>
        <h3>
          Todas nuestras promociones son por tiempo limitado. Aprovecha y
          disfrútalas!
        </h3>
        <div className="promocionesWrapper">
          {images.map(({ img, alt }, index) => {
            return (
              <div key={`promocion${index}`} className="promocion">
                <img src={img} alt={`Promocion ${alt}`} />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
