import React from "react";

export default function Select(props) {
  const RenderLabel = (data) => {
    let innerText = data.label;
    if (data.required) innerText += " *";
    if (data.label) {
      return (
        <label className="text-label" htmlFor={data.name}>
          {innerText}
        </label>
      );
    } else return <></>;
  };

  const RenderSubLabel = (data) => {
    let innerText = data.subLabel;
    if (data.required) innerText += " *";
    if (data.subLabel) {
      return <div className="text-subLabel">{innerText}</div>;
    } else return <></>;
  };

  const GenerateOptions = (options) => {
    if (!options) return;

    const opts = options.map((opt, index) => {
      let renderOpt = <></>;
      if (typeof opt === "string") {
        renderOpt = (
          <option value={opt} key={index}>
            {opt}
          </option>
        );
      } else {
        renderOpt = (
          <option value={opt.valor} key={index}>
            {opt.texto}
          </option>
        );
      }
      return renderOpt;
    });
    return opts;
  };

  const getClassNameString = () => {
    return "input-select";
  };

  // const getInputLabelArrangement = (data) => {
  //   let className = "input-label-wrapper";
  //   if (!data.labelPosition) className += " top";
  //   else className += ` ${data.labelPosition}`;
  //   return className;
  // };

  const generateStyle = (metadata) => {
    let style = {};
    if (!metadata.style) return style;
    else if (isMobile && metadata.style.mobile) style = metadata.style.mobile;
    else if (!isMobile && metadata.style.desktop)
      style = metadata.style.desktop;
    return style;
  };

  const data = props.data;
  const isMobile = props.isMobile || false;
  const formId = props.formId || "";
  const onChange = props.onChange;
  const value = props.value || "";
  const hint = data.hint || "Selecciona...";
  const required = data.required || false;

  return (
    <div className="form-input-wrapper" style={generateStyle(data)}>
      {RenderLabel(data)}
      <select
        className={getClassNameString()}
        name={data.name}
        form={formId}
        value={value}
        onChange={onChange}
        required={required}
      >
        <option value={""} disabled>
          {hint}
        </option>
        {GenerateOptions(data.options)}
      </select>
      {RenderSubLabel(data)}
    </div>
  );
}
