import React, { useEffect } from "react";
import { ElfsightWidget } from "react-elfsight-widget";
import { InstagramEmbed } from "react-social-media-embed";

export default function Publicaciones({ children }) {
  const instagramReviewsLinks = [
    "https://www.instagram.com/p/BjkdWMzFfok/",
    "https://www.instagram.com/p/B-hwAROHvJB/",
    "https://www.instagram.com/p/B7ZYJ6jnzGA/",
    "https://www.instagram.com/p/BzazJL5Htg5/",
    "https://www.instagram.com/p/CBUFNXJHluY/",
    "https://www.instagram.com/p/CQ1Xhfermr-/",
    "https://www.instagram.com/p/COL-_IvngQ2/",
    "https://www.instagram.com/p/CHN3XSrHYGR/",
  ];

  function createInstagramEmbed() {
    return instagramReviewsLinks.map((url, index) => {
      return <InstagramEmbed key={`post${index}`} url={url} />;
    });
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const links = document.getElementsByClassName("eapps-link");
      if (links.length > 0) {
        for (const link of links) {
          link.parentNode.removeChild(link);
        }
        clearInterval(interval);
      }
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <section id="Publicaciones">
      {children}
      <h1>Publicaciones</h1>
      <div className="sectionMain">
        <div className="instagramProfile">
          <ElfsightWidget widgetID="a32d8c03-69b7-40ec-9dad-d484edb7aea5" />
        </div>
        <div className="instagramReviews">
          <h2 className="fondo">Mira que opinan de nosotros!</h2>
          <div className="reviews">{createInstagramEmbed()}</div>
        </div>
      </div>
    </section>
  );
}
