import React from "react";

export default function PieDePagina({ mobileViewport }) {
  const footerClassName = mobileViewport ? "mobile" : undefined;
  return (
    <footer className={footerClassName}>
      <div id="recuadrosWrapper">
        <div className="recuadro" id="direccionFooter">
          <div className="tituloRecuadro">
            <img
              src="/images/elements/DIRECCION.png"
              alt="Dirección"
              className="icon"
            />
            {/* <svg
              className="icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
            >
              <path d="M511.8 287.6L512.5 447.7C512.5 450.5 512.3 453.1 512 455.8V472C512 494.1 494.1 512 472 512H456C454.9 512 453.8 511.1 452.7 511.9C451.3 511.1 449.9 512 448.5 512H392C369.9 512 352 494.1 352 472V384C352 366.3 337.7 352 320 352H256C238.3 352 224 366.3 224 384V472C224 494.1 206.1 512 184 512H128.1C126.6 512 125.1 511.9 123.6 511.8C122.4 511.9 121.2 512 120 512H104C81.91 512 64 494.1 64 472V360C64 359.1 64.03 358.1 64.09 357.2V287.6H32.05C14.02 287.6 0 273.5 0 255.5C0 246.5 3.004 238.5 10.01 231.5L266.4 8.016C273.4 1.002 281.4 0 288.4 0C295.4 0 303.4 2.004 309.5 7.014L416 100.7V64C416 46.33 430.3 32 448 32H480C497.7 32 512 46.33 512 64V185L564.8 231.5C572.8 238.5 576.9 246.5 575.8 255.5C575.8 273.5 560.8 287.6 543.8 287.6L511.8 287.6z" />
            </svg> */}
            <h3>Dirección</h3>
          </div>
          <div className="contenidoRecuadro">
            <p>Diagonal Rancagua #946</p>
            <p>Providencia</p>
            <p>Santiago, Chile</p>
          </div>
        </div>
        <div className="recuadro" id="socialFooter">
          <div className="tituloRecuadro">
            <img
              src="/images/elements/SOCIAL.png"
              alt="Redes Sociales"
              className="icon"
            />
            <h3>Social</h3>
          </div>
          <div className="contenidoRecuadro">
            <a href="https://www.instagram.com/dannysnystylepizza/">
              @dannysnystylepizza
            </a>
            <a href="https://www.facebook.com/dannysnystylepizza">
              Facebook.com/dannysnystylepizza
            </a>
          </div>
        </div>
        <div className="recuadro" id="emailFooter">
          <div className="tituloRecuadro">
            <img
              src="/images/elements/EMAIL.png"
              alt="Email"
              className="icon"
            />
            <h3>Email</h3>
          </div>
          <div className="contenidoRecuadro">
            <a href="mailto:contacto@dannysnystylepizza.cl">
              contacto@dannysnystylepizza.cl
            </a>
          </div>
        </div>
        <div className="recuadro" id="telefonoFooter">
          <div className="tituloRecuadro">
            <img
              src="/images/elements/TELEFONOS DELIVERY.png"
              alt="Teléfonos"
              className="icon"
            />
            <h3>Teléfono Delivery</h3>
          </div>
          <div className="contenidoRecuadro">
            <a href="tel:+56232246760">+56232246760</a>
            <a href="tel:+56232246760">+56944981350</a>
          </div>
        </div>
      </div>
      <div id="copyright">
        <p>&copy; Danny's New York Style Pizza</p>
        <p>
          Diseñado y construido por{" "}
          <a href="http://www.solucionesok.cl">SolucionesOK</a>
        </p>
      </div>
    </footer>
  );
}
