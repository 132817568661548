import React, { useState } from "react";
import Input from "./Input";
import Select from "./Select";
import File from "./File";
import TextArea from "./TextArea";
import processForm from "../../utils/formProcessor";
import "./Form.css";

const InputTypes = [
  "button",
  "checkbox",
  "color",
  "date",
  "datetime-local",
  "email",
  "hidden",
  "image",
  "month",
  "number",
  "password",
  "radio",
  "range",
  "reset",
  "search",
  "tel",
  "text",
  "time",
  "url",
  "week",
];
const FileTypes = ["file"];
const SelectTypes = ["select"];
const WrapperTypes = ["wrapper"];
const TextAreaTypes = ["textarea"]

export default function Form_v2({ initialData, className, meta, submit, inputs }) {
  function createFormValues(initialData) {
    const newFormValues = {};
    for (const input of inputs) {
      newFormValues[input.name] =
        initialData && initialData[input.name] ? initialData[input.name] : "";
    }
    return newFormValues;
  }
  const [formValues, setFormValues] = useState(createFormValues(initialData));

  function onValueChange(e) {
    let newValue;
    if (e.target.type === "checkbox") newValue = e.target.checked;
    else newValue = e.target.value;

    setFormValues({ ...formValues, [e.target.name]: newValue });
  }

  function handleSubmit(e) {
    e.preventDefault();
    const formResponse = {
      formId: meta.formId,
      formData: processForm(e, inputs),
    };
    meta.onSubmit(formResponse);
  }

  function getValue(fieldName, index) {
    let value;
    if (inputs[index]) {
      value = inputs[index].value;
    }
    return value || formValues[fieldName];
  }

  function createFormChildrens(inputs) {
    const formChildrens = inputs.map((inputData, index) => {
      const formChildrensConfig = {
        key: index,
        data: inputData,
        formId: meta.formId,
        onChange: onValueChange,
        value: getValue(inputData.name, index),
      };
      switch (true) {
        case SelectTypes.includes(inputData.type):
          return <Select {...formChildrensConfig} />;
        case FileTypes.includes(inputData.type):
          return <File {...formChildrensConfig} />;
        case InputTypes.includes(inputData.type):
          return <Input {...formChildrensConfig} />;
        case TextAreaTypes.includes(inputData.type):
          return <TextArea {...formChildrensConfig} />;
        case WrapperTypes.includes(inputData.type):
          const className = inputData.className
            ? `formSubWrapper${` ${inputData.className}`}`
            : "formSubWrapper";
          const title = inputData.title ? (
            <h2>{inputData.title}</h2>
          ) : undefined;
          const renderinput = (
            <div className={className} key={index}>
              {title}
              {createFormChildrens(inputData.children)}
            </div>
          );
          return renderinput;
        default:
          return <></>;
      }
    });
    return formChildrens;
  }

  return (
    <form id={meta.formId} className={className} onSubmit={handleSubmit}>
      {createFormChildrens(inputs)}
      <input className={submit.className} type="submit" value={submit.value} />
    </form>
  );
}
