import React from "react";
import DannysProductConstructor from "./DannysProductConstructor";

export default function CatalogoDannys({
  className,
  title,
  data,
  shoppingCartControls,
}) {
  let _className = "catalogoDannys";
  if (className) _className += ` ${className}`;

  const dannysProducts = data.map((dannysProductObj) => {
    const id =
      dannysProductObj.id ||
      `${dannysProductObj.metadata[0].id}&${dannysProductObj.metadata[1].id}`;
    return (
      <DannysProductConstructor
        key={id}
        productData={dannysProductObj}
        shoppingCartControls={shoppingCartControls || undefined}
      />
    );
  });

  return (
    <div className={_className}>
      <h2>{title}</h2>
      <div className="catalogueVisualizer">{dannysProducts}</div>
    </div>
  );
}
