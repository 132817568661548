import React, { useMemo, useEffect } from "react";
import shoppingCartEvents from "./shoppingCartEvents";

const assertEventData = (evt) => {
  if (!evt.view) throw new Error("Nombre de la vista no especificado");
  if (!evt.component) throw new Error("Nombre del componente no especificado");
  if (!evt.event) throw new Error("Nombre del evento no especificado");
};

const customEventList = [];

const eventBus = {
  on(event, callback) {
    document.addEventListener(event, (e) => callback(e.detail));
    customEventList.push(event);
  },
  async dispatch(event, data) {
    console.info("EventBus: Nuevo evento despachado", event, data);
    await document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  async publish(eventData, onCompletion) {
    try {
      assertEventData(eventData);

      eventData = {
        ...eventData,
        app: "Danny's shopping cart",
        timestamp: Math.floor(Date.now()),
      };
      if (onCompletion)
        await eventBus.dispatch(eventData.event, { eventData, onCompletion });
      else return await eventBus.dispatch(eventData.event, { eventData });
    } catch (error) {
      console.error("Error al publicar evento", error);
    }
  },
  remove(event, callback) {
    document.removeEventListener(event, callback);
    customEventList.splice(customEventList.indexOf(event), 1);
  },
};

const EventHandler = React.memo((props) => {
  // Hook utilizado para navegacion nativa entre pantallas
  /**
   * useMemo es un hook de React que acepta dos argumentos, una funcion de calculo y un array de dependencias.
   * Al crear este componente useMemo invoca la funcion de calculo, genera la lista de eventos y la guarda en la variable
   * Si en la siguiente renderizacion las dependencias no mutaron, el valor de la lista de eventos se mantiene
   * Este hook es necesario por que estamos definiendo las acciones de los eventos como una funcion anonima, a esta se le asigna
   * una nueva referencia en cada renderizacion y por lo tanto estamos volviendo a generar la lista en cada iteracion.
   * useMemo "guarda" esta referencia y previene este comportamiento.
   */
  const memoizedEventList = useMemo(() => {
    const EventList = [
      // EVENTOS DE CARRO DE COMPRA
      ...shoppingCartEvents,
    ];
    return EventList;
  }, []);

  useEffect(() => {
    let isMounted = true;

    // EventCollector se suscribe a los eventos de la aplicacion
    memoizedEventList.forEach((event) => {
      eventBus.on(event.name, (data) => {
        if (!isMounted) return;
        const { eventData, onCompletion } = data;
        event.action(eventData, onCompletion);
      });
    });

    return () => {
      isMounted = false;
      // EventCollector se desuscribe de los eventos de la aplicacion
      memoizedEventList.forEach((event) => {
        eventBus.remove(event.name);
      });
    };
  }, [memoizedEventList]);

  return <></>;
});

export { EventHandler, eventBus, customEventList };
