import React from "react";

export default function File(props) {
  const RenderLabel = (data) => {
    let innerText = data.label;
    if (data.required) innerText += " *";
    if (data.label) {
      return (
        <label className="text-label" htmlFor={data.name}>
          {innerText}
        </label>
      );
    } else return <></>;
  };

  const RenderSubLabel = (data) => {
    let innerText = data.subLabel;
    if (data.required) innerText += " *";
    if (data.subLabel) {
      return <div className="text-subLabel">{innerText}</div>;
    } else return <></>;
  };

  const getClassNameString = (data) => {
    let className = `input-field`;
    if (data.className) className += ` ${data.className}`;
    return className;
  };

  // const getInputLabelArrangement = (data) => {
  //   let className = "input-label-wrapper";
  //   if (!data.labelPosition) className += " top";
  //   else className += ` ${data.labelPosition}`;
  //   return className;
  // };

  const generateStyle = (data) => {
    let style = {};
    if (!data.style) return style;
    else if (isMobile && data.style.mobile) style = data.style.mobile;
    else if (!isMobile && data.style.desktop) style = data.style.desktop;
    else style = data.style;
    return style;
  };

  const isMobile = props.isMobile || false;
  const data = props.data;
  const formId = props.formId || "";
  const onChange = props.onChange;
  //   const value = props.value;

  return (
    <div className="form-input-wrapper" style={generateStyle(data)}>
      {RenderLabel(data)}
      <input
        type={data.type}
        name={data.name}
        className={getClassNameString(data)}
        //   autoComplete={isAutocompletable(data)}
        //   placeholder={data.hint || ""}
        required={data.required || false}
        disabled={data.disabled || false}
        form={formId}
        onChange={onChange}
        //   value={value || ""}
      />
      {RenderSubLabel(data)}
    </div>
  );
}
