import React from "react";

export default function Encabezado() {
  return (
    <header>
      <div id="headerBackground">
        <div id="logo">
          <img src="/images/elements/LOGOAZUL.png" alt="Logo Danny's Pizza" />
        </div>
      </div>
    </header>
  );
}
