import React from 'react'

export default function TextArea(props) {
    const RenderLabel = (data) => {
      let innerText = data.label;
      if (data.required) innerText += " *";
      if (data.label) {
        return (
          <label className="text-label" htmlFor={data.name}>
            {innerText}
          </label>
        );
      } else return <></>;
    };
  
    const RenderSubLabel = (data) => {
      let innerText = data.subLabel;
      if (data.required) innerText += " *";
      if (data.subLabel) {
        return <div className="text-subLabel">{innerText}</div>;
      } else return <></>;
    };
  
    const generateStyle = (data) => {
      let style = {};
      if (!data.style) return style;
      else if (isMobile && data.style.mobile) style = data.style.mobile;
      else if (!isMobile && data.style.desktop) style = data.style.desktop;
      else style = data.style;
      return style;
    };
  
    const isMobile = props.isMobile || false;
    const data = props.data;
    const inputProperties = {
      type: data.type,
      name: data.name,
      className: data.className,
      autoComplete: data.autoComplete ? data.autoComplete : "off",
      placeholder: data.hint,
      required: data.disabled,
      form: props.formId,
      onChange: props.onChange,
      hidden: data.hidden ? true : false,
    };
    let className = "form-input-wrapper";
    if (inputProperties.className) className += ` ${inputProperties.className}`;
    if (data.type === "checkbox") {
      className += " checkbox";
      inputProperties.checked = props.value;
    } else {
      inputProperties.value = props.value;
    }
  
    return (
      <div className={className} style={generateStyle(data)}>
        {RenderLabel(data)}
        <textarea {...inputProperties} />
        {RenderSubLabel(data)}
      </div>
    );
}
