const toNumber = (input, obj) => {
  const _newValue = Number.parseFloat(obj[input.name]);
  obj[input.name] = _newValue;
};

const toBoolean = (input, obj) => {
  if (obj[input.name] === "on") obj[input.name] = true;
};

export default function processForm(event, inputs) {
  event.preventDefault();
  
  const data = new FormData(event.target);
  
  const outgoingData = Object.fromEntries(data.entries());
  // console.log("event.target", event.target);
  // console.log("inputs", inputs);
  // console.log("outgoingData", outgoingData);

  for (const input of inputs) {
    if (input.type === "number") toNumber(input, outgoingData);
    if (input.type === "checkbox") toBoolean(input, outgoingData);
  }

  return outgoingData;
}
