import React from "react";
import products from "../data/products.json";
import CatalogoDannys from "../sections/CatalogoDannys";

export default function MenuPizzas({ children, shoppingCartControls }) {
  const pizzas = {
    classics: [],
    veggieLovers: [],
    meatLovers: [],
  };

  for (const product of products) {
    if (pizzas[product.subclase]) pizzas[product.subclase].push(product);
  }

  return (
    <section>
      {children}
      <h1>Menu pizzas</h1>
      <CatalogoDannys
        title={"Pizza Classics"}
        data={pizzas.classics}
        className={"classics"}
        shoppingCartControls={shoppingCartControls || undefined}
      />
      <CatalogoDannys
        title={"Veggie Lovers Pizza"}
        data={pizzas.veggieLovers}
        className={"veggieLovers"}
        shoppingCartControls={shoppingCartControls || undefined}
      />
      <CatalogoDannys
        title={"Meat Lovers Pizza"}
        data={pizzas.meatLovers}
        className={"meatLovers"}
        shoppingCartControls={shoppingCartControls || undefined}
      />
    </section>
  );
}
