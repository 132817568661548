export default class intervalHelper {
  constructor(callback, t) {
    let timerObj;

    this.stop = () => {
      if (timerObj) {
        clearInterval(timerObj);
        timerObj = null;
      }
      return this;
    };

    // start timer using current settings (if it's not already running)
    this.start = function () {
      if (!timerObj) {
        this.stop();
        timerObj = setInterval(callback, t);
      }
      return this;
    };

    // start with new or original interval, stop current interval
    // la expresion 'newT = t' significa que el parámetro 'newT' tiene por defecto 't' en caso que no se suministre un valor al parámetro en la ejecución de la función
    this.reset = function (newT = t) {
      t = newT;
      return this.stop().start();
    };
  }
}
