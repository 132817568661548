import React from "react";

export default function ScreenDimmer({ status, onClick }) {
  const newOnClick = status ? onClick : undefined;
  
  return (
    <div
      id="screenDimmer"
      onClick={newOnClick}
    ></div>
  );
}
