const shoppingCartEvents = [
  // {
  //   name: "solicitaron-agregar-producto",
  //   action: (eventData, onCompletion) => {
  //     if (!eventData.payload)
  //       throw new Error("Se debe especificar el id en el payload del evento");
  //     if (!eventData.payload.id) throw new Error("Se debe especificar el id");
  //   },
  // },
  // {
  //   name: "solicitaron-quitar-producto",
  //   action: (eventData, onCompletion) => {
  //     if (!eventData.payload)
  //       throw new Error("Se debe especificar el id en el payload del evento");
  //     if (!eventData.payload.id) throw new Error("Se debe especificar el id");
  //   },
  // },
];

export default shoppingCartEvents;
