import React from "react";
import products from "../data/products.json";
import CatalogoDannys from "../sections/CatalogoDannys";

export default function OtrosProductos({ children, shoppingCartControls }) {
  const _products = {
    otherSpecialties: [],
    desserts: [],
  };

  for (const product of products) {
    if (_products[product.subclase]) _products[product.subclase].push(product);
    if (_products[product.clase]) _products[product.clase].push(product);
  }

  return (
    <section>
      {children}
      <h1>Otros Productos</h1>
      <CatalogoDannys
        title={"Other Specialties"}
        data={_products.otherSpecialties}
        className={"otherSpecialties"}
        shoppingCartControls={shoppingCartControls}
      />
      <CatalogoDannys
        title={"Desserts"}
        data={_products.desserts}
        className={"desserts"}
        shoppingCartControls={shoppingCartControls}
      />
    </section>
  );
}
