import Inicio from "./Inicio";
// import MenuPizzas from "./MenuPizzas_backup";
import MenuPizzas from "./MenuPizzas";
import OtrosProductos from "./OtrosProductos";
import ArmaTuPizza from "./ArmaTuPizza";
import Promociones from "./Promociones";
import Publicaciones from "./Publicaciones";
import Contacto from "./Contacto";

const sections = {
  Inicio,
  MenuPizzas,
  OtrosProductos,
  ArmaTuPizza,
  Promociones,
  Publicaciones,
  Contacto,
};

export default sections;