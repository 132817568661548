import React from "react";
import Carrousel from "../components/Carrousel/Carrousel";

export default function Inicio({ children }) {
  const slides = [
    { src: "/images/carrousel1.webp", alt: "Dual pizzas" },
    { src: "/images/carrousel2.webp", alt: "Porky's Teaser" },
    { src: "/images/carrousel3.webp", alt: "Hot wings" },
    { src: "/images/carrousel4.webp", alt: "Brownies" },
    { src: "/images/carrousel5.webp", alt: "Veggie Lovers & Meat Lovers" },
  ];

  return (
    <section>
      {children}
      <h1>Inicio</h1>
      <Carrousel slides={slides} />
      <div className="zonaDelivery">
        <h2>Prueba Danny's y diferenciate!</h2>
        <p>
          Buscamos el mejor sabor, buscamos las mejores salsas y creamos sabores
          fusión al más puro New York Style!
        </p>
        <div className="mapa">
          <h3>Zona Delivery</h3>
          <div className="images">
            <img
              src="/images/alcancegrande2.webp"
              className="imgMapa"
              alt="Mapa de alcance"
            />
            <img
              src="/images/leyenda2.png"
              className="imgLeyenda"
              alt="Leyenda del mapa"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
