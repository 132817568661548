import React, { useState } from "react";
import {
  // useShoppingCartController_v2,
  useWindowDimensions,
} from "../utils/customHooks";
import Encabezado from "../sections/Encabezado";
import Navbar from "../components/Navbar";
import PieDePagina from "../sections/PieDePagina";
import NavButton from "../components/NavButton";
import ScreenDimmer from "../components/ScreenDimmer";
// import CarritoCompra from "../sections/CarritoCompra";
// import ShoppingButton from "../components/ShoppingButton";

export default function MainView({ children, data }) {
  const [navIsOpen, setNavIsOpen] = useState(false);
  const [shoppingCartIsOpen, setShoppingCartIsOpen] = useState(false);
  // const {
  // shoppingCart,
  // shoppingCartItemCounter,
  // useOrderButtons,
  // } = useShoppingCartController_v2();
  const { width } = useWindowDimensions();

  const dimmerStatus = navIsOpen || shoppingCartIsOpen ? true : false;
  const mobileViewport = width < 1080;
  let bodyClassName = mobileViewport ? "mobile" : undefined;
  if (navIsOpen) bodyClassName += " navOpen";
  if (shoppingCartIsOpen) bodyClassName += " shopOpen";

  const onNavClick = () => {
    setNavIsOpen(!navIsOpen);
    if (shoppingCartIsOpen) setShoppingCartIsOpen(false);
  };

  // const onShoppingButtonClick = () => {
  //   setShoppingCartIsOpen(!shoppingCartIsOpen);
  //   if (navIsOpen) setNavIsOpen(false);
  // };

  const onScreenDimmerClick = () => {
    setNavIsOpen(false);
    setShoppingCartIsOpen(false);
  };

  const navData = {
    data: data,
    navStatus: navIsOpen,
    onNavClick: onNavClick,
    mobileViewport: mobileViewport,
  };

  // const childrenWithProps = React.Children.map(children, (child) => {
  //   if (React.isValidElement(child)) {
  //     return React.cloneElement(child, {
  //       children: (
  //         <ShoppingButton
  //           onClick={onShoppingButtonClick}
  //           itemCounter={shoppingCartItemCounter}
  //         />
  //       ),
  //       shoppingCartControls: useOrderButtons,
  //     });
  //   }
  //   return child;
  // });

  return (
    <div id="bodyDiv" className={bodyClassName}>
      <Navbar {...navData} />
      <ScreenDimmer status={dimmerStatus} onClick={onScreenDimmerClick} />
      <main id="mainDiv">
        <NavButton viewportStatus={mobileViewport} onClick={onNavClick} />
        <div id="mainDivNonAbsolutes">
          <Encabezado mobileViewport={mobileViewport} />
          {/* {childrenWithProps} */}
          {children}
          <PieDePagina mobileViewport={mobileViewport} />
        </div>
      </main>
      {/* <CarritoCompra shoppingCart={shoppingCart} /> */}
    </div>
  );
}
