import React, { useMemo } from "react";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import "./Table.css";

const GlobalFilter = ({ tableInstance, addedComponents }) => {
  const { state, setGlobalFilter } = tableInstance;
  const { globalFilter } = state;
  const addedComponentsPlaceholder = addedComponents ? (
    <div className="addedComponents">{addedComponents}</div>
  ) : undefined;

  return (
    <div className="globalFilterWrapper">
      <div className="inputWrapper">
        <label htmlFor="globalFilter">Buscar: </label>
        <input
          type="text"
          name="globalFilter"
          value={globalFilter || ""}
          onChange={(e) => {
            setGlobalFilter(e.target.value);
          }}
        />
      </div>
      {addedComponentsPlaceholder}
    </div>
  );
};

const Pagination = ({ tableInstance }) => {
  const {
    state,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
  } = tableInstance;

  const { pageIndex, pageSize } = state;

  const cantidadElementosPagina = [10, 25, 50];
  return (
    <div className="paginacionWrapper">
      <div>
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          Página anterior
        </button>
        <p>
          Página{" "}
          <strong>
            {pageIndex + 1} de {pageOptions.length}
          </strong>
        </p>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          Página siguiente
        </button>
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>
      </div>
      <div>
        <select
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
        >
          {cantidadElementosPagina.map((pageSize) => {
            return (
              <option key={pageSize} value={pageSize}>
                Mostrar {pageSize} elementos
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default function Table({ tableConfig }) {
  const columns = useMemo(() => {
    return tableConfig.columns;
  }, [tableConfig.columns]);
  const data = useMemo(() => {
    return tableConfig.data;
  }, [tableConfig.data]);

  const addons = [useGlobalFilter, useSortBy, usePagination];

  const tableInstance = useTable({ columns, data }, ...addons);

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  const GlobalFilterPlaceholder =
    tableConfig.meta &&
    tableConfig.meta.isGlobalFilter === false ? undefined : (
      <GlobalFilter
        tableInstance={tableInstance}
        addedComponents={tableConfig.meta.addedGlobalFilterComponent}
      />
    );

  const PaginationPlaceholder =
    tableConfig.meta && tableConfig.meta.isPagination === false ? undefined : (
      <Pagination tableInstance={tableInstance} />
    );

  return (
    <div className="tableComponentWrapper tcw1">
      {GlobalFilterPlaceholder}
      <div className="tableWrapper">
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => {
              return (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    const shouldBeSorted = !React.isValidElement(
                      column.render("Header")
                    );
                    const headerProps = shouldBeSorted
                      ? {
                          ...column.getHeaderProps(
                            column.getSortByToggleProps()
                          ),
                        }
                      : {
                          ...column.getHeaderProps(),
                        };
                    return (
                      <th {...headerProps}>
                        {column.render("Header")}
                        {shouldBeSorted ? (
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " ↓"
                                : " ↑"
                              : ""}
                          </span>
                        ) : undefined}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              if (tableConfig.meta)
                var { idRowSelected, onRowClick } = tableConfig.meta;
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  onClick={
                    onRowClick ? () => onRowClick(row.values) : undefined
                  }
                  className={
                    idRowSelected && row.values.id === idRowSelected
                      ? "selected"
                      : undefined
                  }
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps([
                          { className: cell.column.className },
                        ])}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {PaginationPlaceholder}
    </div>
  );
}
