import React from "react";
import { Link } from "react-router-dom";

const processNavbarData = (data, mobileViewport, onNavClick) => {
  const links = [];
  for (const navLinkKey in data) {
    if (Object.hasOwnProperty.call(data, navLinkKey)) {
      const path = data[navLinkKey].path;
      const navName = data[navLinkKey].navName;
      const onClick = mobileViewport ? onNavClick : undefined;
      const link = (
        <li key={navName} onClick={onClick}>
          <Link to={path}>{navName}</Link>
        </li>
      );
      links.push(link);
    }
  }
  return <ul>{links}</ul>;
};

const MobileNavbar = ({ children, navStatus }) => {
  const navClassName = navStatus ? "active" : undefined;

  return (
    <>
      <div id="mobileNav" className={navClassName}>
        {children}
      </div>
    </>
  );
};

export default function Navbar({
  data,
  navStatus,
  onNavClick,
  mobileViewport,
}) {
  const navbarData = processNavbarData(data, mobileViewport, onNavClick);
  const render = mobileViewport ? (
    <MobileNavbar
      navStatus={navStatus}
      onNavClick={onNavClick}
      children={navbarData}
    />
  ) : (
    <div id="desktopNav">{navbarData}</div>
  );

  return <nav>{render}</nav>;
}
