import React from "react";
import currencyFormatter from "../utils/currencyFormatter";
import { plusIcon, minusIcon } from "../utils/iconSelector";

export default function dannysPriceHandler(data, shoppingCartControls) {
  function CreatePrecios({ id, precios }) {
    return precios.map(({ etiqueta, valor }) => (
      <div key={`${id}${etiqueta}`} className="container">
        <div className="precio">
          <div className="tipoWrapper">
            <p className="tipo">{etiqueta}</p>
          </div>
          <p className="valor">{currencyFormatter(valor)}</p>
        </div>
      </div>
    ));
  }

  function CreateBotonesPedidos(data, shoppingCartControls) {
    return data.precios.map(({ etiqueta, valor, id }) => {
      const { prodCounter, addProduct, removeProduct } =
        shoppingCartControls(id);
      const attributes = {
        disabled: prodCounter > 0 ? false : true,
      };
      return (
        <div key={`${data.id}${etiqueta}`} className="container">
          <div className="precio">
            <p className="tipo">{etiqueta}</p>
            <p className="valor">{currencyFormatter(valor)}</p>
          </div>
          <div className="botonesAgregar">
            <button onClick={removeProduct} {...attributes}>
              {minusIcon()}
            </button>
            <p className="contador">{prodCounter}</p>
            <button onClick={addProduct}>{plusIcon()}</button>
          </div>
        </div>
      );
    });
  }

  return {
    precios: CreatePrecios(data),
    botonesPedidos: shoppingCartControls
      ? CreateBotonesPedidos(data, shoppingCartControls)
      : undefined,
  };
}
