import React from "react";
import { Route } from "react-router-dom";
import { BrowserRouter, Routes } from "react-router-dom";
import views from "../views";
import MainView from "../views/MainView";
import { EventHandler } from "../events/EventHandler";

export const createRoutes = (metaRoutes) => {
  const viewsRoutesArr = [];
  for (const routeObj in metaRoutes) {
    if (Object.hasOwnProperty.call(metaRoutes, routeObj)) {
      const path = metaRoutes[routeObj].path;
      const SubViewComponent = views[routeObj];

      viewsRoutesArr.push(
        <Route
          exact
          key={routeObj}
          path={path}
          element={
            <MainView data={metaRoutes}>
              <SubViewComponent />
            </MainView>
          }
        />
      );
    }
  }
  return viewsRoutesArr;
};

export default function RouterApp({ metaRoutes }) {
  const routes = createRoutes(metaRoutes);

  return (
    <BrowserRouter>
      <EventHandler />
      <Routes>{routes}</Routes>
    </BrowserRouter>
  );
}
