import React, { useCallback, useMemo, useState } from "react";
import { useEffect } from "react";
import { circleIcon } from "../../utils/iconSelector";
import intervalHelper from "../../utils/intervalHelper";
import "./Carrousel.css";

export default function Carrousel({ slides }) {
  const [slide, setSlide] = useState(0);
  const slidesRendered = getSlides();
  const mapsRendered = getMaps();

  const changeSlide = useCallback(
    (index = (slide + 1) % slides.length) => {
      setSlide(index);
    },
    [slides, slide]
  );

  const sliderIntervalController = useMemo(() => {
    return new intervalHelper(changeSlide, 5000);
  }, [changeSlide]);

  function getSlides() {
    return slides.map(({ src, alt }, index) => {
      const className =
        index === slide ? "recuadroSlide active" : "recuadroSlide";
      return (
        <div key={`image${index}`} className={className}>
          <img src={src} alt={alt} />
        </div>
      );
    });
  }

  function getMaps() {
    return slides.map((_slide, index) => {
      return (
        <div
          className={`map${slide === index ? " active" : ""}`}
          key={`map${index}`}
          onClick={() => {
            if (slide !== index) {
              changeSlide(index);
              sliderIntervalController.reset();
            }
          }}
        >
          {circleIcon()}
        </div>
      );
    });
  }

  useEffect(() => {
    sliderIntervalController.start();
    return () => {
      sliderIntervalController.stop();
    };
  }, [sliderIntervalController]);

  return (
    <div className="slider">
      <div className="slidesWrapper">{slidesRendered}</div>
      <div className="mapWrapper">{mapsRendered}</div>
    </div>
  );
}
