import React, { useState } from "react";
import "./Tabulator.css";

export default function Tabulator({ tabs }) {
  const [currentTab, setCurrentTab] = useState(tabs[0]);

  return (
    <div className="tabulator">
      <div className="tabs">
        {tabs.map(({ tabName }, index) => {
          return (
            <p
              key={tabName}
              className={currentTab.tabName === tabName ? "active" : ""}
              onClick={() => {
                setCurrentTab(tabs[index]);
              }}
            >
              {tabName}
            </p>
          );
        })}
      </div>
      <div className="currentTabRender">{currentTab.component}</div>
    </div>
  );
}
