import React from "react";
import Table from "../components/Table/Table";
import Tabulator from "../components/Tabulator/Tabulator";
import armaTuPizzaData from "../data/armaTuPizza.json";
import currencyFormatter from "../utils/currencyFormatter";

function Base() {
  const tableData = [];
  for (const baseKey in armaTuPizzaData.base) {
    if (Object.hasOwnProperty.call(armaTuPizzaData.base, baseKey)) {
      const baseData = armaTuPizzaData.base[baseKey];
      const row = {};
      row.base = baseKey;
      for (const tamanoKey in baseData) {
        if (Object.hasOwnProperty.call(baseData, tamanoKey)) {
          const tamanoData = baseData[tamanoKey];
          row[tamanoKey] = currencyFormatter(tamanoData);
        }
      }
      tableData.push(row);
    }
  }

  const tableConfig = {
    meta: {
      isPagination: false,
      isGlobalFilter: false,
    },
    columns: [
      { Header: "", accessor: "base" },
      { Header: "Mediana", accessor: "m" },
      {
        Header: "Familiar",
        accessor: "f",
      },
      {
        Header: "Super Familiar",
        accessor: "sf",
      },
    ],
    data: tableData,
  };

  return <Table tableConfig={tableConfig} />;
}

function Ingredientes() {
  const tableData = [];
  for (const ingredientesKey in armaTuPizzaData.ingredientes) {
    if (
      Object.hasOwnProperty.call(armaTuPizzaData.ingredientes, ingredientesKey)
    ) {
      const ingredientes = armaTuPizzaData.ingredientes[ingredientesKey];
      const row = {};
      row.grupo = `Grupo ${ingredientesKey}`;
      for (let index = 0; index < ingredientes.tipos.length; index++) {
        const ingredienteData = ingredientes.tipos[index];
        row.lista =
          index === 0
            ? ingredienteData.etiqueta
            : row.lista + `, ${ingredienteData.etiqueta}`;
      }
      tableData.push(row);
    }
  }

  const tableConfig = {
    className: "ingredienteTable",
    meta: {
      isPagination: false,
      isGlobalFilter: false,
    },
    columns: [
      { Header: "", accessor: "grupo" },
      { Header: "Lista ingredientes", accessor: "lista" },
    ],
    data: tableData,
  };

  return <Table tableConfig={tableConfig} />;
}

function Valor() {
  const tableData = [];
  for (const ingredientesKey in armaTuPizzaData.ingredientes) {
    if (
      Object.hasOwnProperty.call(armaTuPizzaData.ingredientes, ingredientesKey)
    ) {
      const ingredientesData = armaTuPizzaData.ingredientes[ingredientesKey];
      const row = {};
      row.grupo = `Grupo ${ingredientesKey}`;
      for (const precioKey in ingredientesData.precio) {
        if (Object.hasOwnProperty.call(ingredientesData.precio, precioKey)) {
          const precioData = ingredientesData.precio[precioKey];
          row[precioKey] = currencyFormatter(precioData);
        }
      }
      tableData.push(row);
    }
  }

  const tableConfig = {
    meta: {
      isPagination: false,
      isGlobalFilter: false,
    },
    columns: [
      { Header: "", accessor: "grupo" },
      { Header: "Mediana", accessor: "m" },
      {
        Header: "Familiar",
        accessor: "f",
      },
      {
        Header: "Super Familiar",
        accessor: "sf",
      },
    ],
    data: tableData,
  };

  return <Table tableConfig={tableConfig} />;
}

export default function ArmaTuPizza({ children }) {
  const tabs = [
    {
      tabName: "#1: Elige Base",
      component: <Base />,
    },
    { tabName: "#2: Elige Ingredientes", component: <Ingredientes /> },
    { tabName: "#3: Calcula valor", component: <Valor /> },
  ];

  return (
    <section>
      {children}
      <h1>Arma Tu Pizza</h1>
      <Tabulator tabs={tabs} />
    </section>
  );
}
