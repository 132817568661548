import React from "react";
import { useBinaryState } from "../utils/customHooks";
import DannysPriceHandler from "../customComponents/DannysPriceHandler";

const getBotonesPedidosIsActive = (id, shoppingCartData) => {
  let isActive = false;
  for (const itemKey in shoppingCartData) {
    if (Object.hasOwnProperty.call(shoppingCartData, itemKey)) {
      if (itemKey.indexOf(id) > -1) {
        isActive = true;
        break;
      }
    }
  }
  return isActive;
};

function ProductImage({ label, image }) {
  let _imagen;
  if (image) _imagen = <img src={image} alt={label} />;
  else _imagen = <img src="/images/pizzas/pizza_generica.webp" alt={label} />;
  return (
    <div className="productImageWrapper">
      <div className="imgWrapper">{_imagen}</div>
      <h3 className="etiquetaProducto">{label}</h3>
    </div>
  );
}

function DualLabelProductImage({ label1, label2, image }) {
  let _imagen;
  if (image)
    _imagen = (
      <img className="dual" src={image} alt={`${label1} y ${label2}`} />
    );
  else
    _imagen = (
      <img
        className="dual"
        src="/images/pizzas/pizza_generica.webp"
        alt={`${label1} y ${label2}`}
      />
    );
  return (
    <div className="productImageWrapper">
      {_imagen}
      <h3 className="etiquetaProducto e1">{label1}</h3>
      <h3 className="etiquetaProducto e2">{label2}</h3>
    </div>
  );
}

function ProductSummary({
  id,
  descripcion,
  precios,
  botonesPedidos,
  shoppingCartControls,
}) {
  const isBotonesPedidosActive = shoppingCartControls
    ? getBotonesPedidosIsActive(id, shoppingCartControls.shoppingCartData)
    : undefined;
  const { isActive, changeActive } = useBinaryState(isBotonesPedidosActive);
  const changeActiveButton = shoppingCartControls ? (
    <div className="buttonWrapper">
      <button onClick={changeActive}>
        {isActive ? "Agregar Pizza" : "Ver Ingredientes"}
      </button>
    </div>
  ) : undefined;

  let mainStructure;

  if (shoppingCartControls) {
    mainStructure = (
      <div className="productSummaryWrapper">
        <div className="displaySwapperWrapper">
          <div className={`displaySwapper${!isActive ? " active" : ""}`}>
            <div className="preciosWrapper">{precios}</div>
            <div className="ingredientesWrapper">
              <p>{descripcion}</p>
            </div>
          </div>
          <div className={`displaySwapper${isActive ? " active" : ""}`}>
            <div className="cantidadWrapper">
              <h4>Cantidad</h4>
              <div className="agregarPizzaWrapper">{botonesPedidos}</div>
            </div>
          </div>
        </div>
        {changeActiveButton}
      </div>
    );
  } else {
    mainStructure = (
      <div className="productSummaryWrapper">
        <div className="preciosWrapper">{precios}</div>
        <div className="ingredientesWrapper">
          <p>{descripcion}</p>
        </div>
      </div>
    );
  }

  return mainStructure;
}

function UnitaryProduct({ data, shoppingCartControls }) {
  const summaryConfig = {
    id: data.id,
    descripcion: data.descripcion,
    ...DannysPriceHandler(data, shoppingCartControls),
    shoppingCartControls,
  };

  return (
    <>
      <ProductImage label={data.nombre} image={data.imagen} />
      <ProductSummary {...summaryConfig} />
    </>
  );
}

function DualProduct({ data, shoppingCartControls }) {
  const firstSummaryConfig = {
    id: data.metadata[0].id,
    descripcion: data.metadata[0].descripcion,
    ...DannysPriceHandler(data.metadata[0], shoppingCartControls),
    shoppingCartControls,
  };
  const secondSummaryConfig = {
    id: data.metadata[1].id,
    descripcion: data.metadata[1].descripcion,
    ...DannysPriceHandler(data.metadata[1], shoppingCartControls),
    shoppingCartControls,
  };

  return (
    <>
      <ProductSummary {...firstSummaryConfig} />
      <DualLabelProductImage
        label1={data.metadata[0].nombre}
        label2={data.metadata[1].nombre}
        image={data.imagen}
      />
      <ProductSummary {...secondSummaryConfig} />
    </>
  );
}

export default function DannysProductConstructor({
  productData,
  shoppingCartControls,
}) {
  function createProductRouter(productObj) {
    let Component;
    switch (productObj.tipo) {
      case "unitario":
        Component = UnitaryProduct;
        break;
      case "dual":
        Component = DualProduct;
        break;
      default:
        return;
    }
    return (
      <Component
        data={productObj}
        shoppingCartControls={shoppingCartControls || undefined}
      />
    );
  }

  const className =
    productData.tipo === "dual" ? "productWrapper dual" : "productWrapper";

  return (
    <div key={productData.id} className={className}>
      {createProductRouter(productData)}
    </div>
  );
}
